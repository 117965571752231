import { Heading, HStack, Spacer, useDisclosure } from '@chakra-ui/react'
import { FiSidebar } from 'react-icons/fi'

import {
  Page,
  PageBody,
  PageHeader,
  Toolbar,
  ToolbarButton,
} from '@saas-ui-pro/react'
import { Breadcrumbs } from '@ui/lib'

import { usePath, useProjectPath } from '@app/features/core/hooks/use-path'
import { useCurrentUser } from '@app/features/core/hooks/use-current-user'
import { useEffect } from 'react'

interface ProjectPageProps {
  slug: string
}

export function ProjectPage({ slug }: ProjectPageProps) {
  const { project, projects, setProjectId, isLoading } = useCurrentUser()

  useEffect(() => {
    if (project?.slug !== slug) {
      const newProjectId = projects?.find((p) => p.slug === slug)?.id
      if (newProjectId) setProjectId(newProjectId)
    }
  }, [project, projects, setProjectId, slug])

  const sidebar = useDisclosure({
    defaultIsOpen: true,
  })

  const projectsPath = useProjectPath('/projects')

  const breadcrumbs = (
    <Breadcrumbs
      items={[
        { href: projectsPath, title: 'Projects' },
        { title: project?.name },
      ]}
    />
  )

  const toolbar = (
    <Toolbar>
      <Spacer />
      <ToolbarButton
        icon={<FiSidebar />}
        label={sidebar.isOpen ? 'Hide project details' : 'Show project details'}
        onClick={sidebar.onToggle}
      />
    </Toolbar>
  )

  return (
    <Page isLoading={isLoading} key={project?.id}>
      <PageHeader title={breadcrumbs} toolbar={toolbar} />
      <PageBody contentWidth="full" p="0">
        <HStack
          alignItems="stretch"
          width="100%"
          height="100%"
          overflowX="hidden"
          position="relative"
          spacing="0"
        >
          <Heading>TODO</Heading>
        </HStack>
      </PageBody>
    </Page>
  )
}
