import {
  HStack,
  Icon,
  Spacer,
  useDisclosure,
  Text,
  Center,
  Box,
  Code,
} from '@chakra-ui/react'
import { useSnackbar } from '@saas-ui/react'
import { FiSidebar, FiRefreshCw, FiExternalLink, FiCpu } from 'react-icons/fi'

import {
  Page,
  PageBody,
  PageHeader,
  Toolbar,
  ToolbarButton,
} from '@saas-ui-pro/react'
import { Breadcrumbs } from '@ui/lib'

import { usePath, useProjectPath } from '@app/features/core/hooks/use-path'

import {
  GET_PROJECT_SPREADSHEET,
  INFER_GOOGLE_SPREADSHEET,
  SYNC_GOOGLE_SPREADSHEET,
} from '@api/client'

import { useEffect } from 'react'
import React from 'react'
import { googleDocsSpreadsheetUrl } from '@app/features/core/lib/url-helpers'
import {
  GoogleSpreadsheetReactFlow,
  GoogleSpreadsheetReactFlowInstance,
} from '@app/features/tenants/components/flows/google-spreadsheet/flow'
import { EnvironmentDatasourceSelect } from '../../components/datasources/environment-select'
import useStore from '@app/features/tenants/components/flows/google-spreadsheet/store'
import type { RFState } from '@app/features/tenants/components/flows/google-spreadsheet/store'
import {
  Datasource,
  useCurrentUser,
} from '@app/features/core/hooks/use-current-user'
import { useQuery, useMutation } from '@apollo/client'

interface GoogleSheetPageProps {
  id: string
}

export function GoogleSheetPage({ id }: GoogleSheetPageProps) {
  const snackbar = useSnackbar()
  const { project, projectId, projects, setProjectId } = useCurrentUser()
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(GET_PROJECT_SPREADSHEET, {
    variables: { id },
  })
  const spreadsheet = data?.googleSpreadsheetsByPk
  const environment = spreadsheet?.environment

  const sidebar = useDisclosure({
    defaultIsOpen: true,
  })

  const googleSheetsPath = useProjectPath('/google-sheets')

  const breadcrumbs = (
    <Breadcrumbs
      items={[
        { href: googleSheetsPath, title: 'Google Sheets' },
        {
          href: spreadsheet?.spreadsheetId
            ? googleDocsSpreadsheetUrl(spreadsheet.spreadsheetId)
            : undefined,
          title: (
            <HStack>
              <Text>{spreadsheet?.name}</Text>
              <Icon as={FiExternalLink} boxSize={4} mb={1}></Icon>
            </HStack>
          ),
        },
      ]}
    />
  )

  const [selectedDatasource, setSelectedDatasource] =
    React.useState<Datasource | null>(null)

  useEffect(() => {
    if (
      (spreadsheet?.readonlyDatasourceId || spreadsheet?.rwDatasourceId) &&
      !selectedDatasource
    ) {
      const matchingDs =
        environment?.datasources.find(
          (ds: Datasource) =>
            ds.id === spreadsheet?.readonlyDatasourceId ||
            spreadsheet?.rwDatasourceId,
        ) ||
        // If there is only a single datasource, select it by default, otherwise make user choose
        (environment?.datasources?.length == 1
          ? environment?.datasources[0]
          : null)
      if (matchingDs) setSelectedDatasource(matchingDs)
    }
  }, [
    environment?.datasources,
    selectedDatasource,
    selectedDatasource?.id,
    spreadsheet?.readonlyDatasourceId,
    spreadsheet?.rwDatasourceId,
  ])

  const canSyncSheet = React.useMemo<boolean>(() => {
    return (
      selectedDatasource !== null &&
      spreadsheet?.id !== null &&
      selectedDatasource?.id !== null
    )
  }, [selectedDatasource, spreadsheet?.id])

  const [syncSpreadsheet, { loading: isSyncingSpreadsheet }] = useMutation(
    SYNC_GOOGLE_SPREADSHEET,
  )

  const [rfInstance, setRfInstance] =
    React.useState<GoogleSpreadsheetReactFlowInstance | null>(null)

  const reset = useStore((state) => state.reset)

  const [inferSpreadsheet, { loading: isInferringSpreadsheet }] = useMutation(
    INFER_GOOGLE_SPREADSHEET,
    {
      onCompleted: (data) => {
        const inferredSpreadsheet = data?.inferGoogleSpreadsheet
        if (!inferredSpreadsheet?.flow) return

        const sortedNodes = inferredSpreadsheet.flow.nodes
          .map((x) => ({
            ...x,
            style: x.style && JSON.parse(x.style),
            data: x.data && JSON.parse(x.data),
          }))
          // Reactflow parents must come before children
          .sort((a, b) => (a.parentNode ? 1 : -1))

        const edges = inferredSpreadsheet.flow.edges.map((x) => ({
          ...x,
          data: x.data && JSON.parse(x.data),
        }))

        reset({
          nodes: sortedNodes,
          edges: edges,
        } as RFState)

        snackbar.success('Inferred spreadsheet rules from Postgres metadata 🎉')
      },
      onError: (error: any) => {
        const firstError = error?.response?.errors[0]
        const errorExtension = firstError?.extensions
        if (errorExtension?.type === 'sql-exception/permission') {
          snackbar.error({
            description: (
              <Box>
                Failed to infer spreadsheet rules from Postgres metadata, run
                this on the DB to fix:
                <Code>{errorExtension?.sqlToFix};</Code>
              </Box>
            ),
            duration: 10 * 1000,
          })
        } else {
          snackbar.error(
            `Failed to infer spreadsheet rules from Postgres metadata: ${error.message.slice(
              0,
              100,
            )}`,
          )
        }
      },
    },
  )

  const toolbar = React.useMemo(
    () => (
      <Toolbar alignItems={'center'}>
        <Spacer />
        <EnvironmentDatasourceSelect
          name="datasource"
          datasources={environment?.datasources || []}
          selectedDatasource={selectedDatasource}
          onSelectedDatasourceChange={setSelectedDatasource}
        />

        <ToolbarButton
          leftIcon={<FiCpu />}
          variant={'primary'}
          label={'Infer'}
          isLoading={isInferringSpreadsheet}
          onClick={() =>
            spreadsheet?.id &&
            rfInstance &&
            selectedDatasource?.id &&
            inferSpreadsheet({
              variables: {
                datasourceId: selectedDatasource.id,
                googleSpreadsheetId: spreadsheet.id,
                flow: rfInstance.toObject() as any,
              },
            })
          }
        >
          Infer Spreadsheet from Postgres
        </ToolbarButton>

        <ToolbarButton
          leftIcon={<FiRefreshCw />}
          isDisabled={!canSyncSheet}
          isLoading={isSyncingSpreadsheet}
          size="md"
          label={'Sync'}
          variant={'secondary'}
          onClick={async () => {
            rfInstance &&
              spreadsheet?.id &&
              selectedDatasource?.id &&
              (await snackbar.promise(
                syncSpreadsheet({
                  variables: {
                    spreadsheetId: spreadsheet.id,
                    datasourceId: selectedDatasource.id,
                    // FIXME: fix node.extent type with CoordinatePair union
                    flow: rfInstance.toObject() as any,
                  },
                }),
                {
                  loading: 'Syncing sheet...',
                  success: 'Sheet synced successfully 🎉',
                  error: (e) => 'Failed to sync sheet: ' + e.message,
                },
              ))
          }}
        />
        <ToolbarButton
          icon={<FiSidebar />}
          label={sidebar.isOpen ? 'Hide sidebar' : 'Show sidebar'}
          onClick={sidebar.onToggle}
        />
      </Toolbar>
    ),
    [
      environment?.datasources,
      selectedDatasource,
      isInferringSpreadsheet,
      canSyncSheet,
      isSyncingSpreadsheet,
      sidebar.isOpen,
      sidebar.onToggle,
      spreadsheet?.id,
      rfInstance,
      inferSpreadsheet,
      snackbar,
      syncSpreadsheet,
    ],
  )

  return (
    <Page isLoading={isLoading} key={project?.id}>
      <PageHeader title={breadcrumbs} toolbar={toolbar} />
      <PageBody contentWidth="full" p="0">
        {selectedDatasource?.id ? (
          <GoogleSpreadsheetReactFlow
            googleSpreadsheetId={id}
            selectedDatasource={selectedDatasource}
            rfInstance={rfInstance}
            spreadsheet={spreadsheet}
            onRfInstanceChange={setRfInstance}
            sidebar={sidebar}
          />
        ) : (
          <Center height="full" fontSize="4xl">
            Select a datasource to begin
          </Center>
        )}
      </PageBody>
    </Page>
  )
}
