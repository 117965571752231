import * as React from 'react'

import { LinkButton } from '@ui/lib'
import {
  Page,
  PageHeader,
  PageBody,
  Toolbar,
  ToolbarButton,
} from '@saas-ui-pro/react'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Heading,
  HStack,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { useRouter } from '@app/nextjs'
import { Field, Form, FormLayout, useLink, useSnackbar } from '@saas-ui/react'
import { SchemamapLogo } from '@ui/lib/src/logo/schemamap-logo'
import { CodeCopy } from '@app/features/tenants/components/onboarding/wizard/connect-env'
import Image from 'next/image'
// @ts-ignore
import demoLiveShareImage from './demo_database_build_live_share.png'
// @ts-ignore
import demoCopyUriImage from './demo_database_copy_uri.png'
import { useMutation } from '@apollo/client'
import { CREATE_DEMO_PG_DATASOURCE } from '@api/client'

const TechnicalDemo = () => {
  const [step, setStep] = React.useState(0)

  const back = React.useCallback(() => {
    setStep(step - 1)
  }, [step])

  const next = React.useCallback(() => {
    setStep(step + 1)
  }, [step])

  const Link = useLink()

  const [conn, setConn] = React.useState('')

  const snackbar = useSnackbar()

  const [createDemoPgDatasource] = useMutation(CREATE_DEMO_PG_DATASOURCE)

  const router = useRouter()

  const steps = React.useMemo(() => {
    return [
      {
        name: 'step 1',
        title: 'Launch in-browser Postgres DB',
        children: (
          <VStack spacing="4" alignItems={'flex-start'}>
            <Box>
              To allow demoing without any fear of messing up a real database,
              please use an in-browser Postgres DB:
            </Box>
            <Button
              as={Link}
              variant={'primary'}
              py="4"
              target="_blank"
              href={'https://database.build'}
            >
              Open database.build in new tab
            </Button>
            <ButtonGroup>
              <Button
                onClick={next}
                isDisabled={step >= 3}
                colorScheme="primary"
              >
                Next
              </Button>
            </ButtonGroup>
          </VStack>
        ),
      },
      {
        name: 'step 2',
        title: 'Create demo schema with mock data',
        children: (
          <Stack spacing="4" alignItems={'flex-start'}>
            <Box>
              After authenticating with Github, enter this into the right hand
              side pane on your other tab under "What would you like to
              create?":
            </Box>
            <Box maxW="container.xl">
              <CodeCopy
                textToCopy={`Create a well-modeled ERP schema, with multi-tenancy, constraints on most columns (especially tenant_id) and mock data.`}
                onCopyText={
                  'Copied schema generation prompt to clipboard. Go to database.build'
                }
              />
            </Box>
            <Box>
              Check out the diagram and add/modify your schema to make it more
              similar to your application.
            </Box>

            <ButtonGroup>
              <Button
                onClick={next}
                isDisabled={step >= 3}
                colorScheme="primary"
              >
                Next
              </Button>
              <Button onClick={back} isDisabled={step === 0} variant="ghost">
                Back
              </Button>
            </ButtonGroup>
          </Stack>
        ),
      },
      {
        name: 'step 3',
        title: 'Share your in-browser Postgres DB',
        children: (
          <VStack spacing="4" alignItems={'flex-start'}>
            <Image
              src={demoLiveShareImage}
              width={400}
              alt="Screenshot of Live Share button on database.build left sidebar"
            />

            <ButtonGroup>
              <Button
                onClick={next}
                isDisabled={step >= 3}
                colorScheme="primary"
              >
                Next
              </Button>
              <Button onClick={back} isDisabled={step === 0} variant="ghost">
                Back
              </Button>
            </ButtonGroup>
          </VStack>
        ),
      },
      {
        name: 'step 4',
        title: 'Allow Schemamap to connect to your in-browser Postgres DB',
        children: (
          <VStack spacing="4" alignItems={'flex-start'}>
            <Box>
              Copy the URI from the right hand side of your other tab, and paste
              it below:
            </Box>
            <Image
              src={demoCopyUriImage}
              width={806}
              alt="Screenshot of database.build right sidebar with DB URI copying"
            />

            <Form
              disabled={!conn}
              onSubmit={async (_) => {
                await snackbar.promise(
                  createDemoPgDatasource({
                    variables: { connectionString: conn },
                  }),
                  {
                    loading:
                      'Connecting & Installing Schemamap SDK for your datasource...',
                    success: 'Demo Postgres datasource created successfully',
                    error: (e) =>
                      'Failed to create demo Postgres datasource: ' + e.message,
                  },
                )

                router.push('/demo/projects/demo/schema-overview')
              }}
              maxW="container.lg"
              width="100%"
            >
              <Card>
                <CardBody>
                  <VStack spacing={'4'} alignItems={'flex-start'}>
                    <FormLayout w="full">
                      <Field
                        type="text"
                        name="uri"
                        label="Postgres URI"
                        onChange={(e) => setConn(e.target.value)}
                      />
                    </FormLayout>
                    <Box>
                      In the background, we will initialize the Schemamap SDK,
                      like so:
                    </Box>
                    <CodeCopy
                      textToCopy={`brew install schemamap/tap/schemamap && \\\n schemamap init --dbname=postgres --username postgres --dry-run | \\\n psql -f - '${conn}' -c 'set role postgres'`}
                      onCopyText="Copied Schemamap SDK setup command to clipboard"
                    >
                      <Box>brew install schemamap/tap/schemamap && \</Box>
                      <Box>
                        schemamap init --dbname=postgres --username=postgres
                        --dry-run | \
                      </Box>
                      <Box>psql -f - '{conn}' -c 'set role postgres'</Box>
                    </CodeCopy>
                  </VStack>
                </CardBody>
                <CardFooter>
                  <HStack spacing="4">
                    <Button
                      variant="solid"
                      colorScheme="primary"
                      type="submit"
                      isLoading={false}
                      isDisabled={!conn}
                    >
                      Connect & Install SDK
                    </Button>
                    <Box>
                      By clicking this button you agree to the{' '}
                      <Link
                        href="https://schemamap.io/terms"
                        isExternal
                        target="_blank"
                      >
                        Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link
                        href="https://schemamap.io/privacy"
                        isExternal
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Box>
                  </HStack>
                </CardFooter>
              </Card>
            </Form>

            <ButtonGroup>
              <Button onClick={back} isDisabled={step === 0} variant="ghost">
                Back
              </Button>
            </ButtonGroup>
          </VStack>
        ),
      },
    ]
  }, [Link, back, conn, createDemoPgDatasource, next, router, snackbar, step])

  return (
    <Box>
      <Heading as="h1" size="lg" mb="4">
        {steps[step].title}
      </Heading>
      {steps[step].children}
    </Box>
  )
}

export const DemoPage: React.FC = () => {
  const [isTechnical, setIsTechnical] = React.useState<boolean | undefined>(
    undefined,
  )
  const router = useRouter()

  const toolbar = React.useMemo(() => {
    return (
      <Toolbar>
        <ToolbarButton
          label="Sign up for free"
          variant="solid"
          size="lg"
          colorScheme="primary"
          onClick={() => router.push('/signup')}
        ></ToolbarButton>
      </Toolbar>
    )
  }, [router])

  return (
    <Page>
      <PageHeader
        title={
          <HStack spacing={2}>
            <Box width="20px">
              <SchemamapLogo />{' '}
            </Box>
            <Box>Schemamap.io - Demo</Box>
          </HStack>
        }
        toolbar={toolbar}
      />
      <PageBody pt="8" bg="page-body-bg-subtle" contentWidth={'container.2xl'}>
        {isTechnical ? (
          <TechnicalDemo />
        ) : (
          <Stack alignItems={'center'} spacing="6">
            <iframe
              width="100%"
              height="auto"
              src="https://www.youtube.com/embed/0VPz_qYMECQ?si=o6hH-m30HyEmybM_&amp;start=33"
              title="Launch pitch & demo video"
              style={{ border: '0', aspectRatio: '16 / 9' }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>

            <Heading as="h2" size="lg" color="white" mt="6">
              Are you technical?
            </Heading>
            <ButtonGroup spacing="6">
              <Button
                size="lg"
                variant={'primary'}
                onClick={() => setIsTechnical(true)}
              >
                Yes
              </Button>
              <LinkButton size="lg" href="https://schemamap.io/meeting">
                No
              </LinkButton>
            </ButtonGroup>
          </Stack>
        )}
      </PageBody>
    </Page>
  )
}
