export const estimateSnackbarDuration = (text: string): number => {
  // Average reading speed in words per minute
  const wordsPerMinute = 200
  // Convert reading speed to words per second
  const wordsPerSecond = wordsPerMinute / 60

  // Split the text into words
  const words = text.split(/\s+/)
  // Count the number of words
  const wordCount = words.length

  // Calculate the reading time in seconds
  const readingTimeSeconds = wordCount / wordsPerSecond

  // Add a buffer time (e.g., 2 seconds) for comfort
  const bufferTimeSeconds = 2

  // Total duration in seconds
  const totalDurationSeconds = readingTimeSeconds + bufferTimeSeconds

  const maxDurationSeconds = 10

  // Return the duration in milliseconds
  return Math.min(maxDurationSeconds * 1000, totalDurationSeconds * 1000)
}
